<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("app.choose_lecturer") }}
      </h4>
      <div id="modelSelect" class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="lecturerLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>

        <ModelSelect
          v-if="!lecturerLoading"
          :options="lecturersModelSelect"
          v-model="row.lecturer_id"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          :placeholder="$t('app.choose_lecturer')"
          @input="onInputChange"
          :style="$i18n.locale == 'ar' ? 'text-align: right' : ''"
        >
        </ModelSelect>

        <!-- <select 
                    v-if="!lecturerLoading"
                    id="lecturers"
                    class="form-control" 
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                    v-model="row.lecturer_id"
                    v-on:change="onInputChange">
                    <option 
                        v-for="(row, idx) in lecturers" 
                        :key="idx"
                        :value="row.id">
                        {{ row[$i18n.locale]['title'] }}
                    </option>
                </select> -->
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { ModelSelect } from "vue-search-select";

export default {
  name: "CardLecturer",
  components: {
    ModelSelect,
  },
  props: ["lecturer_id"],
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        lecturer_id: this.$props.lecturer_id,
      },
      lecturers: [],
      lecturersModelSelect: [],
      lecturerLoading: true,
    };
  },
  mounted() {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchData();
  },
  methods: {
    onInputChange() {
      const data = {
        input: this.row.lecturer_id,
      };
      this.$emit("lecturerChange", data);
    },

    fetchData() {
      this.lecturerLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/lecturers",
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.lecturerLoading = false;
          this.lecturers = res.data.data.rows;
          // transform to modelSelect
          this.lecturersModelSelect = [];
          this.lecturers.forEach((element) => {
            this.lecturersModelSelect.push({
              value: element["id"],
              text: element[this.$i18n.locale]["title"],
            });
          });
        })
        .catch((err) => {
          this.lecturerLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
